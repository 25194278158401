import { twMerge } from 'tailwind-merge';

interface SkeletonProps {
  height?: number;
  width?: number | string;
  className?: string;
}

export const Skeleton = ({ height = 20, width = '100%', className }: SkeletonProps) => {
  return (
    <div
      className={twMerge('relative overflow-hidden bg-transparent rounded', className)}
      style={{ height, width }}
    >
      <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-transparent via-white-200 to-transparent animate-shimmer"></div>
    </div>
  );
};

export default Skeleton;
