import Box from "@/common/components/Box";
import Checkbox from "@/common/components/Checkbox";
import TextField from "@/common/components/TextField";
import { zodResolver } from "@hookform/resolvers/zod";
import { Eye, EyeOff } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { LoginValidationSchema, loginSchema } from "../../schemas/login.schema";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import MaskedTextField from "@/common/components/MaskedTextField";
import useLogin from "../../hooks/useLogin";
import { getCompanyDomain } from "@/common/services/companyDomainGetSet";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loginError, setLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const from = useMemo(() => location?.state?.from?.pathname || '/company', []);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<LoginValidationSchema>({
    resolver: zodResolver(loginSchema)
  });

  const { isPending, mutate } = useLogin({
    onSuccess: () => {
      setLoginError(false);
      navigate(from, { replace: true });
    },
    onError: (error) => {
      const { errors: { base: errors } } = error?.response?.data as { errors: { base: string[]; }; };

      if (errors && errors.length) {
        setLoginError(true);
        setError('password', { message: errors[0] });
      }
    }
  });

  const onSubmit: SubmitHandler<LoginValidationSchema> = (data) => mutate(data);

  useEffect(() => {
    if (!getCompanyDomain()) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <div className="flex flex-col items-center gap-8 w-screen">
      <img src="/Logo.svg" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className=" flex flex-col gap-4 rounded-lg rounded-b-none backdrop-blur-lg w-[460px]">
          <div className="flex flex-col gap-2 text-center">
            <h3 className="text-2xl font-bold">
              Bem vindo a
            </h3>

            <div className="flex justify-center items-center gap-2">
              <img className="size-6 rounded-full bg-white-300 object-cover" />

              <h4 className="text-[18px]">
                Nome da empresa
              </h4>
            </div>

            <p className="font-light">
              Confirme seus dados para acessar a plataforma.
            </p>
          </div>

          <MaskedTextField
            mask="000.000.000-00"
            label="CPF"
            variant={errors.cpf?.message || loginError ? 'error' : 'default'}
            errorMessage={errors.cpf?.message || undefined}
            {...register('cpf')}
          />

          <TextField
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            RightIcon={showPassword ? <EyeOff /> : <Eye />}
            onIconClick={() => setShowPassword(!showPassword)}
            variant={errors.password?.message ? 'error' : 'default'}
            errorMessage={errors.password?.message || undefined}
            {...register('password')}
          />

          <div className="flex justify-between">
            <Checkbox
              label="Lembrar de mim"
              name="remember-me"
            />
          </div>
        </Box>

        <button type="submit" className="w-full bg-white-800 text-black rounded-b-lg rounded-t-md py-4">
          {isPending ? 'Carregando...' : 'Entrar na empresa'}
        </button>
      </form>

      <div className="flex flex-col items-center">
        <span className="text-[10px] italic font-light">
          Desenvolvido por
        </span>

        <img src='/Avantsoft.svg' />
      </div>
    </div>
  );
};

export default Login;