import Box from '@/common/components/Box'
import Button from '@/common/components/Button'
import MaskedTextField from '@/common/components/MaskedTextField'
import SelectController from '@/common/components/SelectController'
import Spinner from '@/common/components/Spinner'
import TextField from '@/common/components/TextField'
import { nameMask, phoneMask } from '@/common/constants/masks.constant'
import { useRoles } from '@/common/hooks/queries/useRoles'
import { useGoBack } from '@/common/hooks/useGoBack'
import { Mapper } from '@/common/services/mapper'
import { RoleData } from '@/common/types/roles'
import { FormTransformers } from '@/common/utils/formTransformers'
import { useCreateEstablishmentUser } from '@/features/users/hooks/useCreateEstablishmentUser'
import {
  establishmentUserSchema,
  EstablishmentUserValidationSchema,
} from '@/features/users/schemas/create.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { ChevronLeftIcon } from 'lucide-react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { statusOptions } from '../../constants/statusOptions.constant'

const Create = () => {
  const goBack = useGoBack()
  const queryClient = useQueryClient()

  const { data: rolesOptions } = useRoles({
    mapper: Mapper.mapToOptions<RoleData>({
      labelFieldName: 'display_name',
      valueFieldName: 'id',
    }),
  })

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm<EstablishmentUserValidationSchema>({
    resolver: zodResolver(establishmentUserSchema),
  })

  const {
    mutate: createEstablishmentUser,
    isPending: createEstablishmentUserIsPending,
  } = useCreateEstablishmentUser({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['establishmentUsers'] })
      goBack({ fallback: '/users' })
    },
    onError: (error) => {
      FormTransformers.errorsTransformer<EstablishmentUserValidationSchema>({
        setError,
      })(error)
    },
  })

  const onSubmit: SubmitHandler<EstablishmentUserValidationSchema> = (data) => {
    createEstablishmentUser(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-4 items-center">
          <Button
            variant="link-white"
            onClick={() => goBack({ fallback: '/users' })}
          >
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Button>
          <h2 className="text-2xl font-bold">Novo usuário</h2>
        </div>
        <Button
          type="submit"
          className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]"
          disabled={createEstablishmentUserIsPending}
        >
          {createEstablishmentUserIsPending ? (
            <Spinner className="h-6 mx-[23px] fill-white-800" />
          ) : (
            'Confirmar'
          )}
        </Button>
      </div>
      <Box className="flex flex-col gap-4 shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)] overflow-y-auto max-h-[calc(100vh-128px)]">
        <p className="text-lg font-bold">Dados pessoais</p>
        <div className="flex gap-2 w-full justify-between">
          <div className="w-3/4">
            <MaskedTextField
              label="CPF"
              markAsRequired
              mask="000.000.000-00"
              errorMessage={errors?.cpf?.message || undefined}
              {...register('cpf')}
              disabled={createEstablishmentUserIsPending}
            />
            <Button variant="link">Importar dados</Button>
          </div>
          <MaskedTextField
            label="Data de nascimento"
            mask="00/00/0000"
            className="w-1/4"
            errorMessage={errors.date_of_birth?.message || undefined}
            {...register('date_of_birth')}
            disabled={createEstablishmentUserIsPending}
          />
        </div>
        <MaskedTextField
          label="Nome"
          markAsRequired
          mask={nameMask}
          maxLength={64}
          errorMessage={errors.full_name?.message || undefined}
          {...register('full_name')}
          disabled={createEstablishmentUserIsPending}
        />
        <SelectController
          control={control}
          name="user_roles"
          label="Perfil"
          errorMessage={errors.user_roles?.message || undefined}
          options={rolesOptions || []}
          isMulti
          isDisabled={createEstablishmentUserIsPending}
        />
        <SelectController
          control={control}
          name="status"
          label="Status"
          markAsRequired
          errorMessage={errors.status?.message || undefined}
          options={statusOptions}
          isDisabled={createEstablishmentUserIsPending}
        />
        <TextField
          label="Matrícula"
          errorMessage={errors.enrollment_number?.message || undefined}
          {...register('enrollment_number')}
          disabled={createEstablishmentUserIsPending}
        />
        <MaskedTextField
          label="Telefone"
          mask={phoneMask}
          errorMessage={errors.phone?.number?.message || undefined}
          {...register('phone.number')}
          disabled={createEstablishmentUserIsPending}
        />
        <TextField
          label="Email"
          {...register('email')}
          errorMessage={errors.email?.message || undefined}
          disabled={createEstablishmentUserIsPending}
        />
      </Box>
    </form>
  )
}

export default Create
