import Login from "./features/auth/pages/Login";
import companyDomain from "./features/auth/pages/CompanyDomain";
import RecoverCompanyUrl from "./features/auth/pages/RecoverCompanyUrl";
import CompanyProfile from "./features/company/pages/Profile/Show";
import CompanyContract from "./features/company/pages/Contract/Show";
import Company from "./features/company/pages/Dashboard";
import MovementsTotalizerList from "./features/movementsTotalizer/pages/List";
import ProductPricesList from "./features/productPrices/pages/List";
import BillsList from "./features/billingBills/pages/List";
import Consumption from "./features/consumption/pages/Create";
import BillingEventsList from "./features/billingEvents/pages/List";
import UserList from './features/users/pages/List';
import UserCreate from './features/users/pages/Create';
import UserShow from './features/users/pages/Show';
import UserUpdate from './features/users/pages/Update';
import CardCurrentLimitsShow from "./features/cardCurrentLimits/pages/Show";
import RefundCreate from "./features/refund/pages/Create";

export const publicRoutes = [
  { path: '/', comp: companyDomain },
  { path: '/recover-company-domain', comp: RecoverCompanyUrl },
];

export const publicCompanyRoutes = [
  { path: '/login', comp: Login },
]

export const privateCompanyRoutes = [
  { path: '/company', comp: Company },
  { path: '/company/mine', comp: CompanyProfile },
  { path: '/company/mine/contract', comp: CompanyContract },

  { path: '/billing-events', comp: BillingEventsList },
  { path: '/movements-totalizer', comp: MovementsTotalizerList },
  { path: '/billing-bills', comp: BillsList },

  { path: '/product-prices', comp: ProductPricesList },

  { path: '/consumption', comp: Consumption },
  { path: '/card-current-limit', comp: CardCurrentLimitsShow },
  { path: '/refund', comp: RefundCreate },

  { path: '/users', comp: UserList },
  { path: '/users/create', comp: UserCreate },
  { path: '/users/:id/edit', comp: UserUpdate },
  { path: '/users/:id/*', comp: UserShow },
];
