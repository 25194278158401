import { cva } from "class-variance-authority";

export const buttonVariants = cva(
  'py-2 px-4 rounded-sm cursor-pointer',
  {
    variants: {
      variant: {
        default: 'bg-primary-blue hover:bg-primary-blue-hover',
        success: 'bg-success hover:bg-success-hover',
        yellow: 'bg-yellow-op-2 hover:bg-yellow-hover',
        link: 'bg-transparent p-0 text-blue-800 text-sm hover:text-primary-blue-hover',
        error: 'bg-danger',
        neutral: 'bg-neutral-op-8',
        'link-white': 'text-[#ffffffde] hover:text-[#ffffffde] p-0',
        'outline-error': 'bg-transparent text-danger-soft hover:text-danger',
        'outline-text': 'bg-transparent italic font-light',
        'outline-disabled': 'bg-transparent text-white-300'
      }
    },
    defaultVariants: {
      variant: 'default',
    }
  }
)