import Tabs from '@/common/components/Tabs';
import { useEstablishmentUser } from '@/features/users/hooks/useEstablishmentUser';
import { ChevronLeftIcon } from 'lucide-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { userTabs } from '../../constants/tabs.constant';
import Button from '@/common/components/Button';

const Show = () => {
  const { id: userId } = useParams();
  const navigate = useNavigate();

  const { data: establishmentUserData } = useEstablishmentUser({
    id: userId || '',
  });

  if (!userId) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex gap-4 items-center">
          <Link to={"/users"} className="cursor-pointer">
            <ChevronLeftIcon size={40} strokeWidth={1} />
          </Link>

          <h2 className="text-2xl font-bold">
            {establishmentUserData?.full_name}
          </h2>
        </div>

        <Button
          className="shadow-[4px_4px_8px_4px_rgba(0,_0,_0,_0.2)]"
          onClick={() => navigate(`/users/${userId}/edit`)}
        >
          Editar
        </Button>
      </div>

      <Tabs
        tabsListClassName="w-full bg-transparent rounded-none"
        tabsTriggerClassName="h-8 w-full rounded-none border-b border-[#444444] text-white-800 text-xs data-[state=active]:bg-transparent data-[state=active]:text-white-800 data-[state=active]:shadow-none data-[state=active]:border-blue-800"
        tabsContentClassName="mt-6"
        tabsList={userTabs}
      />
    </div>
  );
};

export default Show;
