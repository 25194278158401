import { AreaChart, Coins, DollarSign, FileText, PieChart, Receipt, SquareX, Table2, UserRound, WandSparkles } from "lucide-react";

const routes = [
  {
    label: 'Minha empresa',
    Icon: <PieChart strokeWidth={1} />,
    path: '/company',
  },
  {
    label: 'Financeiro',
    Icon: <Coins strokeWidth={1} />,
    path: [
      { label: 'Transações', Icon: <Receipt strokeWidth={1} />, path: '/billing-events' },
      { label: 'Totalizadores', Icon: <AreaChart strokeWidth={1} />, path: '/movements-totalizer' },
      { label: 'Faturas', Icon: <FileText strokeWidth={1} />, path: '/billing-bills' },
    ]
  },
  {
    label: 'Tabela de preços',
    Icon: <Table2 strokeWidth={1} />,
    path: '/product-prices'
  },
  {
    label: 'Transações',
    Icon: <DollarSign strokeWidth={1} />,
    path: [
      { label: 'Consumo', Icon: <Receipt strokeWidth={1} />, path: '/consumption' },
      { label: 'Consulta', Icon: <WandSparkles strokeWidth={1} />, path: '/card-current-limit' },
      { label: 'Estorno', Icon: <SquareX strokeWidth={1} />, path: '/refund' },
    ]
  },
  {
    label: 'Usuários',
    Icon: <UserRound strokeWidth={1} />,
    path: '/users'
  },
];

export default routes;
