import { forwardRef } from "react"
import { VariantProps } from 'class-variance-authority'
import { Check } from "lucide-react"
import { checkboxVariants } from "./styles"
import { twMerge } from "tailwind-merge"

export interface CheckboxProps extends React.HTMLAttributes<HTMLInputElement>, VariantProps<typeof checkboxVariants> {
  label: string | JSX.Element,
  name: string,
  value?: string
  labelClassName?: string
  boxClassName?: string
  disabled?: boolean
  checked?: boolean
  type?: "radio" | "checkbox"
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  label,
  name,
  id,
  labelClassName = '',
  boxClassName = '',
  ...props
}, ref) => {
  return (
    <label
      className="relative flex cursor-pointer items-center gap-3"
      htmlFor={id}
      data-ripple-dark="true"
      onClick={(e) => e.stopPropagation()}
    >
      <input
        id={id}
        name={name}
        type='checkbox'
        ref={ref}
        className="before:content[''] peer hidden"
        {...props}
      />
      <div className={twMerge("flex justify-center items-center text-transparent peer-checked:text-white-800  pointer-events-none h-5 w-5 border rounded-md bg-gray-disabled border-gray-disabled peer-checked:border-success peer-checked:bg-success shrink-0", boxClassName)}>
        <Check size='14px'/>
      </div>
      <p className={twMerge(`text-sm`, labelClassName) } >{label}</p>
    </label>
  )
})

export default Checkbox