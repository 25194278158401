
import GeneralInformationTab from "../pages/Tabs/GeneralInformation";
import EventsTab from "../pages/Tabs/Events";

export const userTabs = [
  {
    label: 'Dados gerais',
    value: '',
    body: <GeneralInformationTab />,
  },
  {
    label: 'Histórico de vendas',
    value: 'events',
    body: <EventsTab />,
  },
];
